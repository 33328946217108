<template>
  <div class="vue-tempalte">
    <form>
      <h3>{{ 'Incomming calls' }}</h3>
      <br>

      <div v-for="call in calls" :key="call.id">
        <input type="button" @click="$router.push({path: `/videocallcli/${call.id}/${call.from}`})" :value="call.from" class="btn btn-primar">
      </div>

    </form>
  </div>
</template>


<script>
import {socialvue} from '@/config/pluginInit'
import api from "@/api/api";

export default {
  name: 'VideoCallsListPage',
  mounted() {
    socialvue.index();
  },
  data() {
    return {
      calls: [],

      refresh: null
    }
  },
  created() {
    this.startRefresh();
    this.checkCalls();
  },
  beforeDestroy() {
    this.endRefresh();
  },
  methods: {
    startRefresh() {
      this.refresh = setInterval(this.checkCalls, 2000);
    },
    endRefresh() {
      if (this.refresh != null) {
        clearInterval(this.refresh);
        this.refresh = null;
      }
    },
    checkCalls() {

      api
        .post('calls/checkCalls', {
          token: this.token
          
        })
        .then((response) => {
          this.calls = response.data.calls;
        });
    },
  },
  computed: {
    token() {
      return this.$store.getters["Setting/token"];
    }
  }
};
</script>

<style scoped>
input[type="button"] {
  background-color: #ef476f;
  color: white;
  width: 100%;
  height: 50px;
  margin-top: 20px;
}
</style>
